@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: local('Poppins'), url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: local('Poppins'), url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
}

*[role='button']:focus {
  outline: none;
  border: 1px solid #735cdd;
  box-shadow: 0px 0px 2px 0px #735cdd;
}

/* 
  * Remove focus on smartphones, touchscreens
  * Mobile devices use hover styles as focus as well 
  * TODO: Need to find a better way to make mobile devices act on focus
  * without it being super disruptive and obstrusive - removing for now
*/
@media (hover: none) and (pointer: coarse) {
  *[role='button']:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

/* https://websemantics.uk/tools/responsive-font-calculator/ */
/* 0.875rem(14px) @ 20rem(320px) increasing to 1.5rem(24px) @ 120rem(1920px) */
html {
  font-size: min(max(0.875rem, calc(0.875rem + ((1vw - 0.2rem) * 0.625))), 1.5rem);
  /* Where: 0.625 = 100 * font-size_difference / viewport_width_difference */

  /* Safari resize fix */
  min-height: 0vw;
}

html,
body {
  min-height: 100vh;
  height: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
